<template>
  <div class="csn-language">
    <a
      class="nav-link casino-btn casino-btn-default csn-nav-btn csn-language-button"
      :class="{ 'csn-language-button-selected': isOpen }"
      @click="toggleSelect"
    >
      <LanguageIconBase v-if="isOpen" />
      <span v-else>{{ selectedLanguage }}</span>
    </a>
    <div v-if="isOpen" class="csn-language-select">
      <div
        v-for="(name, key) in language"
        :key="key"
        class="csn-language-select-item"
        :class="{
          'csn-language-select-item-selected': defineIsSelected({
            key,
            locale: $i18n.locale,
          }),
        }"
        @click="changeLanguage(key)"
      >
        <LanguageIconBase />
        <span>{{ name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Language as LanguageEnum, LANGUAGE_SELECT } from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: LANGUAGE_SELECT,
  components: {
    LanguageIconBase: () => import('@/components/svg/LanguageIconBase'),
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    language: () => LanguageEnum,
    selectedLanguage() {
      return this.$i18n.locale.toUpperCase()
    },
  },
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen
    },
    changeLanguage(key) {
      const currentRoute = this.$router.history.current

      this.toggleSelect()
      this.$i18n.locale = key
      navigateTo({
        name: currentRoute.name,
        params: { ...currentRoute.params, lang: key },
      })
    },
    defineIsSelected: ({ key, locale }) => key === locale,
  },
}
</script>
